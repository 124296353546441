import { CdkScrollable } from '@angular/cdk/scrolling';
import { DecimalPipe, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { Store, select } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { LineChartModule } from '@swimlane/ngx-charts';
import * as shape from 'd3-shape';
import { DateTime } from 'luxon';
import { EloScoreboardModel, MainScoreboardItemModel, OffenseDefenseScoreboardModel, TeamMemberRole } from 'models';
import { switchMap, withLatestFrom } from 'rxjs';
import { AppState } from 'store';
import { IPlayer } from 'store/players/players.model';
import { selectAllPlayers } from 'store/players/players.selectors';
import { ScoreboardType } from 'store/scoreboards/scoreboards.model';
import { selectActiveSeason } from 'store/season/season.reducer';
import { LuxonPipe } from '../pipes/luxon.pipe';
import { SortDescPipe } from '../pipes/sort-desc.pipe';

export interface ScoreboardHistoryModalCotext {
    notQualified: Array<number>;
    type: ScoreboardType;
}

interface ChartEntry {
    name: string;
    series: Array<SeriesEntry>;
}

interface SeriesEntry {
    name: string | Date;
    value: number;
}

@Component({
    selector: 'app-scoreboard-history-modal',
    templateUrl: './scoreboard-history-modal.component.html',
    styleUrls: ['./scoreboard-history-modal.component.scss'],
    imports: [
        NgSwitch,
        NgSwitchCase,
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        MatRadioGroup,
        FormsModule,
        MatRadioButton,
        NgIf,
        LineChartModule,
        NgFor,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        DecimalPipe,
        TranslateModule,
        LuxonPipe,
        SortDescPipe,
    ],
})
export class ScoreboardHistoryModalComponent implements OnInit {
    public chartData: Array<ChartEntry> = [];
    public curve = shape.curveMonotoneY;

    public readonly type: ScoreboardType;
    public playersCount = 0;

    public yAxisTickFormattingBinded: (value: any) => void;

    public yAxisMode: 'position' | 'points' = 'position';

    #data: Record<string, Array<any>>;
    #players: Array<IPlayer>;

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly context: ScoreboardHistoryModalCotext,
        private readonly http: HttpClient,
        private readonly store: Store<AppState>,
    ) {
        this.type = context.type;
        this.yAxisTickFormattingBinded = this.yAxisTickFormatting.bind(this);
    }

    ngOnInit(): void {
        this.store
            .pipe(select(selectActiveSeason))
            .pipe(
                switchMap((season) =>
                    this.http.get<Record<string, Array<{ id: number }>>>(`/api/scoreboards/${this.context.type}/history`, {
                        params: { season: String(season.id || '') },
                    }),
                ),
                withLatestFrom(this.store.pipe(select(selectAllPlayers))),
            )
            .subscribe(([data, players]) => {
                const knownPlayers = new Set<number>();
                Object.values(data).forEach((entry) => {
                    entry.forEach((x) => knownPlayers.add(x.id));
                });

                this.#data = data;
                this.#players = players.filter(
                    (x) =>
                        x.teamMemberRole === TeamMemberRole.Player &&
                        this.context.notQualified.indexOf(x.id) === -1 &&
                        knownPlayers.has(x.id),
                );
                this.chartData = this.createChartSeries();
            });
    }

    public yAxisModeChanged(mode: 'position' | 'points') {
        this.yAxisMode = mode;
        this.chartData = this.createChartSeries();
    }

    public createChartSeries() {
        const players = this.#players.reduce<Record<number, ChartEntry>>((acc, player) => {
            acc[player.id] = {
                name: player.displayName,
                series: [],
            };
            return acc;
        }, {});

        if (this.yAxisMode === 'position') {
            return this.fromPosition(this.#data, players);
        } else if (this.yAxisMode === 'points') {
            switch (this.type) {
                case 'main':
                    return this.fromPoints<MainScoreboardItemModel>(this.#data, players, (x) => x.avaragePoints);
                case 'defense-given':
                case 'offense':
                    return this.fromPoints<OffenseDefenseScoreboardModel>(this.#data, players, (x) => x.avgGoals);
                case 'defense-received':
                    return this.fromPoints<OffenseDefenseScoreboardModel>(this.#data, players, (x) => x.avgGoals * -1);
                case 'elo':
                    return this.fromPoints<EloScoreboardModel>(this.#data, players, (x) => x.eloPoints);

                default:
                    return [] as Array<ChartEntry>;
            }
        }
    }

    private fromPoints<T extends { position?: number; id?: number }>(
        data: Record<string, Array<T>>,
        players: Record<number, ChartEntry>,
        pointsGetter: (item: T) => number,
    ) {
        Object.entries(data).forEach(([date, data]) => {
            const dateDate = new Date(date);
            data.filter((x) => players[x.id])
                .sort((a, b) => a.position - b.position)
                .forEach((x) => {
                    players[x.id].series.push({ name: dateDate, value: pointsGetter(x) });
                });
        });

        return Object.values(players)
            .reduce<Array<ChartEntry>>((acc, item) => {
                if (item.series.length) {
                    acc.push(item);
                }
                return acc;
            }, [])
            .sort((a, b) => {
                if (this.type === 'defense-received') {
                    return a.series.at(-1).value - b.series.at(-1).value;
                } else {
                    return b.series.at(-1).value - a.series.at(-1).value;
                }
            });
    }

    private fromPosition(data: Record<string, Array<{ position: number; id: number }>>, players: Record<number, ChartEntry>) {
        this.playersCount = Object.keys(players).length;

        Object.entries(data).forEach(([date, data]) => {
            const dateDate = new Date(date);
            data.filter((x) => players[x.id])
                .sort((a, b) => a.position - b.position)
                .forEach((x, ix) => {
                    players[x.id].series.push({ name: dateDate, value: this.playersCount - (ix + 1) });
                });
        });

        return Object.values(players)
            .reduce<Array<ChartEntry>>((acc, item) => {
                if (item.series.length) {
                    acc.push(item);
                }
                return acc;
            }, [])
            .sort((a, b) => b.series.at(-1).value - a.series.at(-1).value);
    }

    public xAxisTickFormatting(value: any) {
        if (value instanceof Date) {
            return DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_SHORT);
        }
        return value;
    }

    yAxisTickFormatting(value: number) {
        if (this.yAxisMode === 'position') {
            const newValue = this.playersCount - value;
            if (newValue % 1 === 0) {
                return String(newValue);
            }
            return null;
        } else {
            switch (this.type) {
                case 'defense-received':
                    return value * -1;
                default:
                    return value;
            }
        }
    }
}
