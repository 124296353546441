<ng-container [ngSwitch]="type">
    <h4 *ngSwitchCase="'main'" mat-dialog-title class="modal-title text-center">
        {{ 'SCOREBOARD_HISTORY.MAIN_TITLE' | translate }}
    </h4>
    <h4 *ngSwitchCase="'teams'" mat-dialog-title class="modal-title text-center">
        {{ 'SCOREBOARD_HISTORY.TEAMS_TITLE' | translate }}
    </h4>
    <h4 *ngSwitchCase="'defense-received'" mat-dialog-title class="modal-title text-center">
        {{ 'SCOREBOARD_HISTORY.DEF_RECEIVED_TITLE' | translate }}
    </h4>
    <h4 *ngSwitchCase="'defense-given'" mat-dialog-title class="modal-title text-center">
        {{ 'SCOREBOARD_HISTORY.DEF_GIVEN_TITLE' | translate }}
    </h4>
    <h4 *ngSwitchCase="'offense'" mat-dialog-title class="modal-title text-center">
        {{ 'SCOREBOARD_HISTORY.OFFENSE_TITLE' | translate }}
    </h4>
</ng-container>

<mat-dialog-content class="scrollbar">
    <div class="text-center">
        <mat-radio-group color="primary" class="my-5" [ngModel]="yAxisMode" (ngModelChange)="yAxisModeChanged($event)">
            <mat-radio-button class="me-2" value="position">{{ 'STATISTICS.CHARTS_BY_POSITION' | translate }}</mat-radio-button>
            <mat-radio-button value="points">{{ 'STATISTICS.CHARTS_BY_POINTS' | translate }}</mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="chart-wrapper" *ngIf="yAxisMode === 'position'">
        <ngx-charts-line-chart
            [xAxis]="true"
            [yAxis]="true"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [showGridLines]="true"
            [showRefLines]="true"
            [results]="chartData"
            [autoScale]="true"
            [timeline]="true"
            [legend]="true"
            [yAxisLabel]="'STATISTICS.POSITION' | translate"
            [xAxisLabel]="'STATISTICS.DATE' | translate"
            [xAxisTickFormatting]="xAxisTickFormatting"
            [yAxisTickFormatting]="yAxisTickFormattingBinded"
            [curve]="curve"
            [legendTitle]="'STATISTICS.LEGEND' | translate"
        >
            <ng-template #tooltipTemplate let-model="model"> {{ playersCount - model.value }}. {{ model.series }} </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model">
                {{ model[0]?.name | luxon: 'DATE_SHORT' }}
                <div style="text-align: left">
                    <div *ngFor="let i of model | sortDesc: 'value'">{{ playersCount - i.value }}. {{ i.series }}</div>
                </div>
            </ng-template>
        </ngx-charts-line-chart>
    </div>
    <div class="chart-wrapper" *ngIf="yAxisMode === 'points'">
        <ngx-charts-line-chart
            [xAxis]="true"
            [yAxis]="true"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [showGridLines]="true"
            [showRefLines]="true"
            [results]="chartData"
            [autoScale]="true"
            [timeline]="true"
            [legend]="true"
            [yAxisLabel]="'STATISTICS.AVG_POINTS' | translate"
            [xAxisLabel]="'STATISTICS.DATE' | translate"
            [xAxisTickFormatting]="xAxisTickFormatting"
            [yAxisTickFormatting]="yAxisTickFormattingBinded"
            [curve]="curve"
            [legendTitle]="'STATISTICS.LEGEND' | translate"
        >
            <ng-template #tooltipTemplate let-model="model"> {{ model.value | number: '1.2-2' }} - {{ model.series }} </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model">
                {{ model[0]?.name | luxon: 'DATE_SHORT' }}
                <div style="text-align: left">
                    <div *ngFor="let i of model | sortDesc: 'value'">{{ i.value | number: '1.2-2' }} - {{ i.series }}</div>
                </div>
            </ng-template>
        </ngx-charts-line-chart>
    </div>
</mat-dialog-content>
<div mat-dialog-actions class="float-right">
    <button mat-button mat-dialog-close>{{ 'COMMON.CLOSE' | translate }}</button>
</div>
