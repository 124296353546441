import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';

type DateTimeFormat = 'fromNow' | 'DATETIME_FULL' | 'DATETIME_SHORT' | 'TIME_SIMPLE' | 'DATE_SHORT' | 'DATETIME_MED' | 'DATE_HUGE';

@Pipe({
    name: 'luxon',
    pure: false,
})
export class LuxonPipe implements PipeTransform, OnDestroy {
    onTranslationChange: Subscription;
    onLangChange: Subscription;
    onDefaultLangChange: Subscription;

    private _value: string;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private translate: TranslateService,
    ) {}

    private updateValue(value: any, format: DateTimeFormat): string {
        if (!value) {
            this._value = value;
            return;
        }
        const m = DateTime.fromISO(value);
        if (m.isValid) {
            switch (format) {
                case 'fromNow':
                    this._value = m.toRelative();
                    break;

                case 'DATETIME_FULL':
                    // September 4, 1986 8:30 PM
                    this._value = m.toLocaleString(DateTime.DATETIME_FULL);
                    break;

                case 'DATETIME_SHORT':
                    // 9/4/1986 8:30 PM
                    this._value = m.toLocaleString(DateTime.DATETIME_SHORT);
                    break;

                case 'TIME_SIMPLE':
                    // 8:30 PM
                    this._value = m.toLocaleString(DateTime.TIME_SIMPLE);
                    break;

                case 'DATE_SHORT':
                    // 9/4/1986
                    this._value = m.toLocaleString(DateTime.DATE_SHORT);
                    break;

                case 'DATETIME_MED':
                    // September 4, 1986 8:30 PM
                    this._value = m.toLocaleString(DateTime.DATETIME_MED);
                    break;

                case 'DATE_HUGE':
                    // September 4, 1986 8:30 PM
                    this._value = `${m.toLocaleString(DateTime.DATE_HUGE)}`;
                    break;
            }
        } else {
            this._value = `invalid date: ${value}`;
            return;
        }
    }

    transform(value: any, format?: DateTimeFormat): any {
        // if there is a subscription to onLangChange, clean it
        this.dispose();

        // subscribe to onTranslationChange event, in case the translations change
        if (!this.onTranslationChange) {
            this.onTranslationChange = this.translate.onTranslationChange.subscribe(() => {
                this.changeDetector.markForCheck();
            });
        }

        // subscribe to onLangChange event, in case the language changes
        if (!this.onLangChange) {
            this.onLangChange = this.translate.onLangChange.subscribe(() => {
                this.changeDetector.markForCheck();
            });
        }

        // subscribe to onDefaultLangChange event, in case the default language changes
        if (!this.onDefaultLangChange) {
            this.onDefaultLangChange = this.translate.onDefaultLangChange.subscribe(() => {
                this.changeDetector.markForCheck();
            });
        }
        this.updateValue(value, format);
        return this._value;
    }

    /**
     * Clean any existing subscription to change events
     * @private
     */
    private dispose(): void {
        if (typeof this.onTranslationChange !== 'undefined') {
            this.onTranslationChange.unsubscribe();
            this.onTranslationChange = undefined;
        }

        if (typeof this.onLangChange !== 'undefined') {
            this.onLangChange.unsubscribe();
            this.onLangChange = undefined;
        }

        if (typeof this.onDefaultLangChange !== 'undefined') {
            this.onDefaultLangChange.unsubscribe();
            this.onDefaultLangChange = undefined;
        }
    }
    ngOnDestroy(): void {
        this.dispose();
    }
}
